import {
  NEWS_FEED_REQUEST,
  NEWS_FEED_FETCH_FAILURE,
  NEWS_FEED_FETCH_SUCCESS,
  FEATURES_REQUEST,
  FEATURES_SUCCESS,
  FEATURES_FAILURE
} from "./actionTypes";

export const newsfeedrequest = (params) => ({
  type: NEWS_FEED_REQUEST,
  payload: params,
});

export const newsfeedfetchsuccess = (data) => ({
  type: NEWS_FEED_FETCH_SUCCESS,
  payload: data,
});

export const newsfeedfetchfailure = (error) => ({
  type: NEWS_FEED_FETCH_FAILURE,
  payload: error,
});


export const featurerequest = (params) => ({
    type: FEATURES_REQUEST,
    payload: params,
  });
  
  export const featuresuccess = (data) => ({
    type: FEATURES_SUCCESS,
    payload: data,
  });
  
  export const featurefailure = (error) => ({
    type: FEATURES_FAILURE,
    payload: error,
  });