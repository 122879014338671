import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../Style/Program.css";
// import backgroundimg from '../../assets/programbackimg.png'

function Program({ Programdata, testrequest }) {
  const [programs, setPrograms] = useState([]);
  const [token, setToken] = useState(false);
  const navigate = useNavigate();
  // console.log(token)

  // console.log('programs',programs)

  useEffect(() => {
    if (Programdata) {
      setPrograms(Programdata);
    }
    const authtoken = localStorage.getItem("token");
    if (authtoken !== null) {
      setToken(true);
    }
  }, [Programdata]);

  // console.log("programdata", programs);

  const getTaskStatusClass = (taskStatus, trackingStatus) => {
    console.log(
      "getTaskStatusClass",
      trackingStatus,
      trackingStatus === "In progress"
    );
    if (
      taskStatus === "Pending" &&
      (trackingStatus === "Assigned" || trackingStatus === "Inprogress")
    ) {
      return "task-orange";
    } else if (taskStatus === "Completed" && trackingStatus === "Completed") {
      return "task-green";
    } else if (taskStatus === "Pending" && trackingStatus === null) {
      return "task-gray";
    }
    return "";
  };
  // const calculateProgress = (journey) => {
  //   const totalTasks = journey.length;
  //   const completedTasks = journey.filter(
  //     (task) => task.task_status === "Completed" && task.tracking_status === "Completed"
  //   ).length;
  //   return Math.round((completedTasks / totalTasks) * 100);
  // };

  const getProgressBarColor = (taskStatus, trackingStatus, flow_status) => {
    if (
      taskStatus === "Completed" &&
      trackingStatus === "Completed" &&
      flow_status !== "Begin"
    ) {
      return "green";
    } else if (
      taskStatus === "Pending" &&
      (trackingStatus === "Assigned" || trackingStatus === "Inprogress")
    ) {
      return "orange";
    } else {
      return "gray";
    }
  };

  const getstatus = (taskStatus, trackingStatus, item) => {
    // console.log(item)
    if (
      taskStatus === "Pending" &&
      (trackingStatus === "Assigned" || trackingStatus === "Inprogress")
    ) {
      return {
        title: item.content_name,
        name: item.content_type,
        route: item.route,
        postion: "Next",
        contentId: item.contentId,
      };
    }
    // else if (taskStatus === "Pending" && trackingStatus === null) {
    //   console.log("getstatus456>>>><<<<<<<", item);
    //   return {
    //     name: item.content_type,
    //     route: item.route,
    //     postion: "Later",
    //   };
    // }
    // // else if (taskStatus === "Pending" && trackingStatus === "Assigned") {
    // //   return {
    //     name: item.content_type,
    //     route: item.route,
    //   };
    // // }
    return "";
  };

  if (!programs.length) {
    return <div style={{ height: "100vh" }}>No programs available</div>;
  }

  const onclickcontent = (navigation, id, item) => {
    console.log("item------->", item);
    navigate(`${navigation}?id=${id}`, { state: { item } });
  };

  return (
    <div style={{ marginBottom: "5%", height: "100vh" }}>
      <div className="program-img">
        <h2 style={{ color: "white" }}>Program</h2>
      </div>
      {programs.map((program, programIndex) => (
        <div key={programIndex}>
          <div className="journey-container">
            <div style={{ width: "100%" }}>
              <span>{program.program_name}</span>
              {/* Progress bar */}
              <div className="progress-bar-container">
                {program.journey.map((item, index) => {
                  const progressColor = getProgressBarColor(
                    item.task_status,
                    item.tracking_status,
                    item.flow_status
                  );
                  return (
                    <div
                      key={index}
                      className={`progress-bar-segment ${progressColor}`}
                      style={{ width: `${100 / program.journey.length}%` }}
                    ></div>
                  );
                })}
              </div>
              <ol className="journey">
                {program.journey.map((item, index) => {
                  const taskClass = getTaskStatusClass(
                    item.task_status,
                    item.tracking_status
                  );
                  return (
                    <li key={index} className={taskClass}>
                      <div className="step">
                        <span>
                          <img
                            src={item.image_path}
                            alt="step"
                            width={30}
                            height={30}
                          />
                        </span>
                      </div>
                      <div>
                        <p
                          onClick={() => {
                            if (
                              taskClass === "task-green" ||
                              taskClass === "task-orange"
                            )
                              onclickcontent(item.route, item.contentId, item);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          {item.content_name}
                        </p>
                      </div>
                    </li>
                  );
                })}
              </ol>
            </div>
            <div className="Program-card">
              {program.journey.map((item, index) => {
                const task = getstatus(
                  item.task_status,
                  item.tracking_status,
                  item
                );
                if (!task) return null;
                return (
                  <div
                    // className="task-with-line"
                    key={index}
                    style={{ width: "90%" }}
                  >
                    <p
                      style={{
                        textAlign: "center",
                        marginBottom: "10%",
                        fontWeight: "500",
                      }}
                    >
                      {task.title}
                    </p>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <div style={{ alignSelf: "center" }}>
                        <span style={{ fontWeight: "bold" }}>
                          {task.postion}
                        </span>
                      </div>
                      <button
                        className="program-button"
                        onClick={() =>
                          navigate(`${task.route}?id=${task.contentId}`)
                        }
                      >
                        {task.name}
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Program;
