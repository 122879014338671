import axios from 'axios';
import { put } from 'redux-saga/effects';
import store from '../Store/Store';
import { Api } from './Api';
import { logoutSuccess } from '../Container/Login/action';

const axiosInstance = axios.create({
  baseURL: Api.Apiurl
});

axiosInstance.interceptors.request.use(
  (config) => {
    const { token } = store.getState().login;
    const storedToken = localStorage.getItem('token');
    const finalToken = token || storedToken;
    if (finalToken) {
      config.headers['Authorization'] = `Bearer ${finalToken}`;
    }
    return config;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('token');
      alert('Your session has expired. Please log in again.');
      put(logoutSuccess());
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

let isAlertShown = false;

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      if (!isAlertShown) {
        isAlertShown = true;
        alert('Your session has expired. Please log in again.');
        localStorage.removeItem('token');
        logoutSuccess();
        window.location.href = '/login';
        setTimeout(() => {
          isAlertShown = false;
        }, 1000);
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
