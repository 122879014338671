  import { put, takeEvery, call } from "redux-saga/effects";
  import {
    MODULE_REQUEST,
    GET_MODULE_REQUEST,
    VIDEO_DURATION_UPDATE_REQUEST,
  } from "./actionType";
  import {
    modulefetchsuccess,
    modulefetchfailure,
    Getmodulesuccess,
    Getmodulefailure,
    VideoupdateSuccess,
    VideoupdateFailure,
  } from "./action";
  import axiosInstance from "../../common/AxiosConfig";

  function* moduleSaga(action) {
    // const response = yield call(axios.get, `${Api.Apiurl}/external/home/module`, {
    //     params: action.payload
    // });
    // console.log('response',response)
    try {
      const response = yield call(axiosInstance.get, `/external/home/module`, {
        params: action.payload,
      });
      yield put(modulefetchsuccess(response.data));
    } catch (error) {
      yield put(modulefetchfailure(error.message));
    }
  }

  export function* modulerootSaga() {
    yield takeEvery(MODULE_REQUEST, moduleSaga);
  }

  function* GetmoduleSaga(action) {
    const id = action.payload.id;
    const programid = action.payload.program_id
    // console.log("idgetmodules", id);
    try {
      const response = yield call(
        axiosInstance.get,
        `/external/modules/${id}?programId=${programid}`,
        {}
      );
      // console.log("Nextstepsuccess-->", response.data);
      yield put(Getmodulesuccess(response.data));
    } catch (error) {
      // console.log('Nextsteperror-->', error)
      yield put(Getmodulefailure(error.message));
    }
  }

  export function* GetmodulerootSaga() {
    yield takeEvery(GET_MODULE_REQUEST, GetmoduleSaga);
  }

  function* VideoupdateSaga(action) {
    // console.log('action.payloadvideo-->', action.payload)
    const id = action.payload.Id;
    const program_id = action.payload.program_id
    // console.log('action.payloadvideo-->', program_id)
    const params = {
      module_content_id: action.payload.contentId,
      status: action.payload.status,
      duration: action.payload.duration,
    };
    // console.log("GetmoduleSaga", id);
    try {
      const response = yield call(
        axiosInstance.post,
        `external/modules/${id}?programId=${program_id}`,
        params,
        {}
      );
      // console.log("Nextstepsuccess-->", response.data);
      yield put(VideoupdateSuccess(response.data));
    } catch (error) {
      // console.log('Nextsteperror-->', error)
      yield put(VideoupdateFailure(error.message));
    }
  }

  export function* VideoupdaterootSaga() {
    yield takeEvery(VIDEO_DURATION_UPDATE_REQUEST, VideoupdateSaga);
  }
