import React, { Component } from "react";
import Testpage from "../../Component/Pages/Test";
import Modal from "../../common/Modal";
import Lottie from "lottie-react";
import successaimation from "../../assets/success-animation.json";
import failureanimation from "../../assets/failure-animation - 1732103704913.json";

export class Test extends Component {
  constructor(props) {
    super(props);

    this.state = {
      urlId: "",
      item: {},
      successmsg: "",
      isModalOpen: false,
      userscore: 0,
      passed: 0,
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get("id");
    const { item } = location.state || {};
    this.setState({
      urlId: id,
      item,
    });
    console.log("item---------------------->", item);
  }

  componentDidUpdate(prevProps) {
    const {
      Answermessage,
      TestRequest,
      NextStepRequest,
      NextstepResult,
      navigate,
    } = this.props;
    const { urlId, item } = this.state;
    if (prevProps.Answermessage !== Answermessage) {
      // console.log("Answermessage------>", Answermessage);
      if (Answermessage.passed === 1) {
        this.setState({
          isModalOpen: true,
          successmsg: Answermessage.message,
          userscore: Answermessage.score,
          passed: Answermessage.passed,
        });
        TestRequest({
          id: urlId,
          program_id: item.program_id,
        });
        NextStepRequest({
          id: item.program_id,
          program_id: item.program_id,
        });
      } else {
        this.setState({
          isModalOpen: true,
          successmsg: Answermessage.message,
          userscore: Answermessage.score,
          passed: Answermessage.passed,
        });
      }
    }
    if (prevProps.NextstepResult !== NextstepResult) {
      console.log("NextstepResultupdate--->", NextstepResult);
      if (NextstepResult.success === 1) {
        // if (NextstepResult.data.length > 0) {
        //   const id = NextstepResult.data[0].contentId;
        //   navigate(`${NextstepResult.data[0].route}?id=${id}`, {
        //     state: { item: NextstepResult.data[0] },
        //   });
        // }
      }
    }
  }

  onclickNext = () => {
    const { NextstepResult, navigate, NextStepRequest, QuestionRequest } =
      this.props;
    const { item, passed } = this.state;
    console.log("onclicknextstep--->", NextstepResult);
    if (passed === 1) {
      const nextStep = NextstepResult.data[0];
      NextStepRequest({
        id: nextStep.contentId,
        program_id: nextStep.program_id,
      });
      if (NextstepResult.data.length > 0) {
        navigate(`${nextStep.route}?id=${nextStep.contentId}`,{
          state: { item: NextstepResult.data[0] },
        });
      }
    } else {
      window.location.reload()
    }
    this.Modalclose();
  };
  Modalclose = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  render() {
    const {
      TestRequest,
      Testdata,
      QuestionRequest,
      NextStepRequest,
      NextstepResult,
    } = this.props;
    const { item, isModalOpen, successmsg, userscore, passed } = this.state;
    console.log("item--->", item);
    return (
      <div>
        <Testpage
          TestRequest={TestRequest}
          Testdata={Testdata}
          QuestionRequest={QuestionRequest}
          item={item}
          onclickNext={this.onclickNext}
          NextStepRequest={NextStepRequest}
          NextstepResult={NextstepResult}
        />
        <Modal isOpen={isModalOpen} onClose={this.Modalclose}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Lottie
              animationData={passed === 1 ? successaimation : failureanimation}
              loop={true}
              autoplay={true}
              style={{ height: "100px", width: "100px" }}
            />
          </div>
          <p style={{ fontWeight: "bold" }}>{successmsg}</p>
          <p style={{ textAlign: "center" }}>
            Your Scored {parseInt(userscore)}%
          </p>
          <div style={{ textAlign: "center" }}>
            <button className="next-btn" onClick={this.onclickNext}>
              Next
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Test;
