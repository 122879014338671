import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "../Style/Question.css";

function Test({
  TestRequest,
  Testdata,
  QuestionRequest,
  item,
  NextStepRequest,
}) {
  const [selectedOptions, setSelectedOptions] = useState({});
  const [TestData, setTestData] = useState([]);
  const location = useLocation();
  // const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");

  console.log('testdata====>', TestData)

  // const attendedQuestion =
  //   TestData &&
  //   TestData.some((testItem) =>
  //     testItem.journey.some((questionData) => questionData.attended === 1)
  //   );
  // console.log('TestData---->',TestData)


  useEffect(() => {
    TestRequest({ id });
  }, [id, TestRequest]);

  useEffect(() => {
    if (Testdata !== undefined) {
      setTestData(Testdata);
    }
  }, [Testdata]);

  const handleOptionChange = (questionId, option) => {
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [questionId]: option,
    }));
  };

  const getOptionsArray = (questionData) => {
    return [
      questionData.option_one,
      questionData.option_two,
      questionData.option_three,
      questionData.option_four,
      questionData.option_five,
      questionData.option_six,
    ].filter((option) => option !== null);
  };

  // const getPreselectedAnswer = (questionData) => {
  //   return questionData.answer ? questionData.answer : null;
  // };
  const renderAnswerStatus = (questionData, option) => {
    if (selectedOptions[questionData.id] === option) {
      return "selected";
    }
    if (questionData.answer === option) {
      return "correct";
    }
    return "";
  };

  const handleSubmit = () => {
    console.log('item==============>',item)
    const userAnswers = Object.keys(selectedOptions).map((questionId) => ({
      questioId: parseInt(questionId),
      answer: selectedOptions[questionId],
    }));

    const params = {
      flowId: item.flow_id,
      programId: item.program_id,
      usersAnswers: userAnswers,
    };
    console.log('handlesubmit--->', params)
    QuestionRequest({
      params,
      id,
    });
  };

  const onclickNext = () => {
    console.log('onclicknext', item)
    NextStepRequest({
      id: item.contentId,
      program_id:item.program_id
    });
  };

  return (
    <div className="question-container">
      {TestData.length === 0 ? (
        <div>Loading questions...</div>
      ) : (
        TestData.map((testItem) => (
          <div key={testItem.id} className="test">
            <div className="test-title">
              <h2>{testItem.name}</h2>
            </div>
            <div className="questions">
              {testItem.journey.map((questionData, index) => {
                const preselectedAnswer = questionData.answer;
                return (
                  <div key={questionData.id} className="question">
                    <div className="question-text">
                      <span>
                        {index + 1}. {questionData.question}
                      </span>
                    </div>
                    <div className="options">
                      {getOptionsArray(questionData).map(
                        (option, optionIndex) => (
                          <div key={optionIndex} className="option">
                            <input
                              type="radio"
                              id={`q${questionData.id}-option${optionIndex}`}
                              name={`q${questionData.id}`}
                              value={option}
                              checked={
                                selectedOptions[questionData.id] === option ||
                                preselectedAnswer === option
                              }
                              onChange={() =>
                                handleOptionChange(questionData.id, option)
                              }
                              disabled={questionData.attended === 1}
                            />
                            <label
                              htmlFor={`q${questionData.id}-option${optionIndex}`}
                              className={renderAnswerStatus(
                                questionData,
                                option
                              )}
                            >
                              {option}
                            </label>
                          </div>
                        )
                      )}
                    </div>
                    {/* <div
                      style={{
                        marginTop: "5%",
                        color: "green",
                        fontWeight: "bold",
                      }}
                    >
                      <span>{`Correct-answer : ${questionData.Correct_answer}`}</span>
                    </div> */}
                  </div>
                );
              })}
              {TestData && TestData[0].test_status ? (
                <div>
                  <button style={{ marginLeft:"50%" }} className="question-submit-btn" onClick={onclickNext}>
                    Next Task
                  </button>
                </div>
              ) : (
                <div>
                  <button
                    className="question-submit-btn"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              )}
            </div>
          </div>
        ))
      )}
    </div>
  );
}

export default Test;
