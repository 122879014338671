import React, { useState, useEffect } from "react";
import "./Sidenavbar.css";
import signal from "../../assets/signal.png";
import Vector from "../../assets/vector.png";
import file from "../../assets/file.png";
import setting from "../../assets/setting.png";
import feed from "../../assets/feed.png";
import Window from "../../assets/window.png";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { featurerequest } from "../../Container/NewsFeed/action";


const Sidenavbar = ({ feacturedata, sidebar }) => {
  const [navItems, setnavItems] = useState([]);
  const location = useLocation();
  const getActiveIndex = () => {
    return navItems.findIndex((item) => item.href === location.pathname);
  };
  const [activeIndex, setActiveIndex] = useState(getActiveIndex());
  useEffect(() => {
    setActiveIndex(getActiveIndex());
  }, [location]);

  useEffect(() => {
    feacturedata();
  }, []);
  useEffect(() => {
    if (sidebar !== undefined) {
      setnavItems(sidebar);
    }
  }, [sidebar]);

  return (
    <div className="main">
      <div className="sidenav">
        <div className="sidenav-list">
          {navItems.map((item, index) => (
            <div
              key={index}
              className={`nav-list ${activeIndex === index ? "active" : ""}`}
              onClick={() => setActiveIndex(index)}
            >
              <img src={item.icons} alt="icon" />
              <a style={{ width: "100%" }} href={item.route}>
                <div>{item.name}</div>
              </a>
            </div>
          ))}
        </div>
      </div>
      <div className="help">
        <div className="help-text">
          <div>
            <img
              className="circle"
              src="https://falcon-asset.s3.ap-south-1.amazonaws.com/admin-ui/Question.png"
              alt="question"
            />
          </div>
          <div className="help-center">Help Center</div>
          <div className="help-center-sub">
            Having Trouble in Learning. Please contact us for more questions.
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  console.log("sidebar=====>", state.newsfeed.featuredata);
  return {
    sidebar: state.newsfeed.featuredata.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    feacturedata: () => dispatch(featurerequest()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidenavbar);
