import {
  NEWS_FEED_REQUEST,
  NEWS_FEED_FETCH_FAILURE,
  NEWS_FEED_FETCH_SUCCESS,
  FEATURES_REQUEST,
  FEATURES_SUCCESS,
  FEATURES_FAILURE,
} from "./actionTypes";

const initialState = {
  fetchdata: false,
  newsFeed: { data: [] },
  error: null,
  isLoading: false,

  featuredatafetch: false,
  featuredata: [],
  featureerror: null,
  isfeatureLoading: false,
};

const newsfeedReducer = (state = initialState, action) => {
  switch (action.type) {
    case NEWS_FEED_REQUEST:
      return { ...state, isLoading: true };
    case NEWS_FEED_FETCH_SUCCESS:
      return {
        ...state,
        fetchdata: true,
        newsFeed: action.payload,
        error: null,
        isLoading: false,
      };
    case NEWS_FEED_FETCH_FAILURE:
      return {
        ...state,
        fetchdata: false,
        error: action.payload,
        isLoading: false,
      };
    case FEATURES_REQUEST:
      return { ...state, isfeatureLoading: true };
    case FEATURES_SUCCESS:
      return {
        ...state,
        featuredatafetch: true,
        featuredata: action.payload,
        featureerror: null,
        isfeatureLoading: false,
      };
    case FEATURES_FAILURE:
      return {
        ...state,
        featuredata: false,
        featureerror: action.payload,
        isfeatureLoading: false,
      };
    default:
      return state;
  }
};

export default newsfeedReducer;
